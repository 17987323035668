/* You can add global styles to this file, and also import other style files */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

:root {
  --ion-color-info: #cbd5e1;
  --ion-color-charcoal: #475569;
}

.resq-box-shadow {
  box-shadow: 0px 1px 4px 0px rgba(148, 163, 184, 35%);
}

.tag-content {
  background: url('@assets/icons/tag.png') no-repeat center center fixed;
  background-size: contain;
  height: 20px;
}

// Input and Buttons Form CSS Behaviour
form {
  &.ng-submitted {
    .ng-invalid {
      border-left-color: var(--ion-color-danger);
      border-left-width: 3px;
    }
  }
}
.input-form {
  margin-bottom: 16px;
  &:focus-within {
    color: #a855f7;
    input,
    select {
      background-color: #faf5ff;
      border-color: #a855f7;
      &::placeholder {
        color: #a855f7;
      }
    }
  }
}

.social-button {
  background-color: var(--ion-color-primary-contrast);
  border: solid 1px #94a3b8;
  border-radius: 8px;
  padding: 8px;
  height: 48px;
  width: 48px;
  img {
    width: 32px;
    height: 32px;
  }
}

// ResQ Buttons
.resq-button {
  // ui styling
  border-radius: 26px;
  padding: 16px 24px;
  -webkit-box-shadow: 2px 2px 12px 0px rgba(200, 157, 236, 1);
  -moz-box-shadow: 2px 2px 12px 0px rgba(200, 157, 236, 1);
  box-shadow: 2px 2px 12px 0px rgba(200, 157, 236, 1);
  // font property
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  // size property
  height: 58px;
  width: 310px;
  &[disabled] {
    background-color: #d8b4fe;
  }
}
.half-modal-type {
  align-items: center;
  justify-content: center;
  &::part(content) {
    --background: transparent;
    position: absolute;
    bottom: 0px;
  }
  .input-number {
    button:not(.enable) {
      pointer-events: none;
    }
    button.enable {
      filter: brightness(0) invert(0);
    }
  }
}

// ResQ colors
.resq-bg-primary {
  background-color: #581c87;
}
.resq-bg-secondary {
  background-color: #6b21a8;
}
.resq-bg-secondary-slate {
  background-color: #a855f7;
}
.resq-bg-tertiary {
  background-color: #c084fc;
  &:hover {
    background-color: #d8b4fe;
  }
}

.resq-primary {
  color: #581c87;
}
.resq-secondary {
  color: #6b21a8;
}
.resq-secondary-slate {
  color: #a855f7;
}

// ResQ Typography
.resq-charcoal {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
}

// Font style
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.poppins-thin {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Divider
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-charcoal);
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--ion-color-info);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

// Swiper CSS (previously ion-slides)
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-charcoal: #ecebeb;
    --ion-color-charcoal-rgb: 33, 33, 33;
    --ion-color-charcoal-contrast: #ffffff;
    --ion-color-charcoal-contrast-rgb: 236, 235, 235;
    --ion-color-charcoal-shade: #8c8c8c;
    --ion-color-charcoal-tint: #9e9e9e;

    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
